.red-text {
    text-align: center;
    color: rgba(132, 14, 14, 0.814) !important;
}

.table1 {
    color: rgba(132, 14, 14, 0.814);
    font-weight: bold;
    width: 10%;
}

.table2 {
    color: rgba(132, 14, 14, 0.814);
    font-weight: bold;
    width: 70%;
}

.table3 {
    color: rgba(132, 14, 14, 0.814);
    font-weight: bold;
    width: 20%;
}