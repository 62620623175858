.red-text {
    text-align: center;
    color: rgba(132, 14, 14, 0.814) !important;
    font-weight: bold;
}

a:link {
    color: rgba(132, 14, 14, 0.814) ; /* Цвет ссылок */
   }
   a:visited {
    color: rgba(132, 14, 14, 0.814) ; /* Цвет посещенных ссылок */
   }

   .black-text {

    color: black;
   }