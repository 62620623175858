.imj {
    display:block;
    margin:0px auto;
    width: initial;
    height: auto;
}

@media all and (max-width: 1200px){
    .img{
      width:100%;
      height:auto;
    }
  }