.App {
  text-align: center;
}
.box {
  display: flex;
}



h4 {
  text-align: left;
}

@media screen and (min-width: 900px) {
  .img {
  max-width: 50%;
  height: auto;
  border-radius: 25%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.8;
}
}

@media screen and (max-width: 900px) {
  .img {
  max-width: 70%;
  height: auto;
  border-radius: 25%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.8; 
}
}


.img:hover {
  opacity: 1.0;
}

a:hover {
  text-shadow: .25px 0px .1px,
              -.25px 0px .1px;
}

#basic-nav-dropdown {
  color: white;
}



a.dropdown-item {
  color: #ffffff;  
}

.text-white {
  color: white !important;
  text-align: center;
}



.Navbar {
  background-color: rgba(132, 14, 14, 0.814) !important;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Nav {
  align-items: center;
  color: rgba(132, 14, 14, 0.814) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
